import React from "react"
import { DigitalDescriptionStyled } from "./style"
import { Title, SubTitle } from "src/componentsV2/sections/Text"
import howitworksImage from "src/images/home/howitworks.jpg"

const index = ({ className }: any) => {
  return (
    <DigitalDescriptionStyled className={`${className || ""}`}>
      <div className="row items-center justify-between">
        <div className="col md:max-w-98">
          <Title>Building an employee handbook is as simple as 1, 2, 3!
          </Title>

          <SubTitle className="hidden md:block">
            <p className="mb-4">
              Build and design stunning employee handbooks and other important documents with our easy-to-use editor.
            </p>
            Digitally optimized for laptops, desktops, or mobile devices, with search and tracking functionalities built right in.
          </SubTitle>
          <SubTitle className="md:hidden">
            Build and design stunning employee handbooks and other important documents with our easy-to-use editor.
            Digitally optimized for laptops, desktops, or mobile devices, with search and tracking functionalities built right in.
          </SubTitle>
        </div>
        <div className="col text-right">
          <img
            src={howitworksImage}
            alt="Howitworks"
            width="726"
            height="422"
          />
        </div>
      </div>
    </DigitalDescriptionStyled>
  )
}
export default index
